.user-monitoring {

  &__header {

    .rs-nav-item {
      &.active {
        background: rgba(124, 24, 230, .2);
      }
    }
  }

}
@base-color: #f44336;