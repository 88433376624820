@import "~rsuite/styles/index.less";
@import "./core/style/less/variables";
@import "./core/style/less/custom";
@import "./core/style/less/amplify-auth";

iframe#webpack-dev-server-client-overlay{display:none!important}

body {
  margin: 0;
  font-family: Inter;
  font-size: 14px;
  background: #F5F4F6;
}
//
//.modal-open .modal-backdrop {
//  backdrop-filter: blur(5px);
//  background-color: rgba(0, 0, 0, 0.5);
//  opacity: 1 !important;
//}



@base-color: #f44336;