.data-amplify-container {
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 4px 88px -24px rgba(16, 3, 26, 0.25);
}

.amplify-input {
  border-radius: 8px;
  border: 1px solid #E0DDE3;
  background: #FFF;
}

.amplify-button--primary {
  border-radius: 8px;
  background: #6413B9;
}

[data-amplify-authenticator] [data-amplify-router] {
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 4px 88px -24px rgba(16, 3, 26, 0.25);
}

[data-amplify-authenticator] {
  background-color:hsla(260,14%,95%,1);
  background-image:
          radial-gradient(at 1% 98%, hsla(301,100%,97%,1) 0px, transparent 50%),
          radial-gradient(at 44% 98%, hsla(15,100%,97%,1) 0px, transparent 50%),
          radial-gradient(at 13% 30%, hsla(300,100%,97%,1) 0px, transparent 50%),
          radial-gradient(at 100% 0%, hsla(264,100%,93%,1) 0px, transparent 50%),
          radial-gradient(at 100% 40%, hsla(187,100%,88%,1) 0px, transparent 50%);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}

[data-amplify-authenticator] [data-amplify-form] {
  padding: 24px;
}

.amplify-heading.amplify-heading--3 {
  padding: inherit !important;
}

.sigin-h1 {
  color: #130224;
  text-align: center;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 133.333% */
}

.sigin-h2 {
  color: #A39AAC;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 3px;
}