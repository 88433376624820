.rs-sidenav-subtle .rs-sidenav-item.rs-sidenav-item-active {
  color: #F0EEF1;
}

.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn:hover {
  background: transparent;
}

.rr-player {
  box-shadow: none !important;
}