.main {
  &__sidebar {
    width: 274px;
    height: 100vh;
    background: #141216;
    color: #CCC7D1;
    position: fixed;

    .rs-sidenav {


      .rs-dropdown {
        .rs-dropdown-toggle {
          font-size: 14px;
          padding-left: 20px;
        }

        .rs-dropdown-item {
          color: #CCC7D1;
          font-size: 14px;

          &.active {
            background: #29262C;
            border-right: 6px solid #7C18E6;

          }

          &:hover {
            background: #29262C;
          }
        }
      }

      .rs-sidenav-item {
        color: #CCC7D1;
        font-size: 14px;

        &.active {
          background: #29262C;
          border-right: 6px solid #7C18E6;

        }

        &:hover {
          background: transparent;
        }
      }
    }

    &__footer {
      display: flex;

    }
  }

  &__content {
    margin-left: 274px;
    width: calc(100% - 274px);
  }
}

@base-color: #f44336;